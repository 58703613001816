import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import { HambergerMenu, CloseCircle } from "iconsax-react";
import ButtonBox from "../Components/ButtonBox/ButtonBox";
import { ChevronDown } from "feather-icons-react";
import AssetPath from "../AssetPath/AssetPath";
import { Calendar } from "feather-icons-react";

const menuItems = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Agenda",
    link: "/agenda",
  },
  {
    title: "Sponsors",
    link: "/sponsors",
  },
  {
    title: "About Us",
    link: "/about-us",
  },
  {
    title: "Event & Registration",
    link: "/events-and-registration",
  },
];

export default function Header() {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeLink, setActiveLink] = useState(menuItems[0].link); // State for active link
  const navigate = useHistory();

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsMenuActive(false);
  };

  const MenuItem = ({ title, link, submenu }) => {
    return (
      <li>
        <Link
          to={link}
          className={`${submenu ? "parent-submenu" : "child-submenu"} ${
            activeLink === link ? "active" : ""
          }`}
          onClick={() => handleLinkClick(link)}
        >
          {title}
          {submenu ? <ChevronDown size={14} /> : ""}
        </Link>
        {submenu && submenu.length > 0 && (
          <div className="sub-menu">
            <div className="menu-details">
              <div className="links">
                <h4>Our Services</h4>
                <ul>
                  {submenu.map((subitem, index) => (
                    <MenuItem key={index} {...subitem} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </li>
    );
  };

  const NavigationMenu = ({ menuItems }) => {
    return (
      <ul>
        {menuItems.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </ul>
    );
  };

  return (
    <React.Fragment>
      <div className="header">
        {/* <div className="top-header d-flex align-items-center justify-content-between">
          <div className="social-media d-flex align-items-center">
            {FooterLinks.map((data, i) => (
              <ul className="list-unstyled d-flex align-items-center" key={i}>
                {data.socialMedia.map((social, i) => (
                  <li>
                    <Link
                      href={social.url}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {social.icon}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
          <div className="phone-address d-flex align-items-center justify-content-between">
            <span className="font-4 text-gray d-flex align-items-center">
              <MapPin className="me-1 text-primary" size={16} />
              Understock, Switzerland
            </span>
            <span className="font-2 text-gray font-weight-bold d-flex align-items-center">
              <PhoneCall className="me-1 text-primary" size={16} />
              +41-93736 476 734
            </span>
            <span className="font-4 text-gray d-flex align-items-center">
              <Clock className="me-1 text-primary" size={16} />
              9:30 am - 6:30 pm
            </span>
          </div>
        </div> */}

        <nav>
          <div className="flex-menu">
            <div className="brand">
              <Link to="/">
                <img
                  src={AssetPath.brand.pharmaSos}
                  className="dark-logo"
                  alt="Pharma-SOS | Commercial Operations Community"
                />
              </Link>
            </div>
            <div
              className={`right-menu flex-design ${
                isMenuActive ? "active" : ""
              }`}
            >
              <div className="nav-menu">
                <NavigationMenu menuItems={menuItems} />
              </div>

              <div className="cta d-flex">
                {/* <Link to="/events-and-registration" className="me-3">
                  <div className="join-conference d-flex align-items-center justify-content-center">
                    <div className="icon bg-secondary d-flex align-items-center justify-content-center rounded-circle me-2">
                      <Calendar size={20} className="text-white" />
                    </div>
                    <div className="cont">
                      <span className="d-block font-6 text-gray">
                        Join Us Here
                      </span>
                      <span className="d-block font-2 text-black font-weight-medium">
                        Our Conferences
                      </span>
                    </div>
                  </div>
                </Link> */}
                <ButtonBox
                  variant="primary"
                  label="Contact Us"
                  onClick={() => navigate.push("/contact-us")}
                  rightArrowIcon
                />
              </div>
            </div>
            <div
              className={`hamburger-menu ${isMenuActive ? "fixed" : ""}`}
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              {isMenuActive ? <CloseCircle /> : <HambergerMenu />}
            </div>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
}
